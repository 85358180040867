// material ui
import { useTheme } from '@mui/material/styles';
import { Typography, InputLabel, Box, Tooltip, TooltipProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// project import
import './style.css';
import { getIconComponentById } from './iconUtility';
import { getLineComponentById, getColorComponentById, ColorStyle } from './lineUtility';
import { useTranslation } from 'utils/locales/utilityFunctions';
import useSpeciesData from "hooks/useSpeciesData";
import { getPolygonComponentById } from "./polygonUtility";

type SelectorProps = {
   title?: string,
   value?: string,
   options: any[],
   changeHandler?: (e: SelectChangeEvent<string>) => void,
   label: boolean,
   fullWidth: boolean,
   firstItemCustomValue?: boolean,
   inputRef?: React.Ref<any>,
   error?: boolean,
   legendA?: boolean,
   lineColor?: string,
   required?: boolean,
   disabled?: boolean,
   type?: string,
   tooltipPlacement?: TooltipProps["placement"],
   field?: any,
}

const Selector = ({ title, value, options, label, fullWidth, firstItemCustomValue, inputRef, error, legendA, required, disabled, type, lineColor, field, tooltipPlacement, changeHandler }: SelectorProps) => {
   const height = 18;
   const width = 18;

   const theme = useTheme();
   const { translate } = useTranslation();
   const { getLineStyle, getSpeciesColor } = useSpeciesData();

   return (
      <>
         {(title && !label) && <Typography variant="h5" sx={{ mb: 1 }}>
            {title}
         </Typography>}

         <FormControl fullWidth={fullWidth} required={required} disabled={disabled} >
            {label && <InputLabel sx={{
               marginTop: "-5px",
               '&.Mui-focused': {
                  marginTop: "0px",
               },
               '&.MuiInputLabel-shrink': {
                  marginTop: "0px",
               },
            }} id="demo-simple-select-label" color="secondary">{title}</InputLabel>}

            <Select
               displayEmpty={true}
               value={value}
               onChange={changeHandler ? changeHandler : undefined}
               labelId="simple-select-helper-label"
               id="simple-select-helper"
               color="secondary"
               inputRef={inputRef}
               error={error}
               {...field}
            >
               {(firstItemCustomValue && value && !options.some(option => option.id === value)) && (
                  <MenuItem key={value} value={value} disabled className="MenuItem">{value}</MenuItem>
               )}
               {options.map(el => {
                  if (el.status === 'hidden') return null;
                  // Get line style (color, dash, width)
                  let lineStyle = null;
                  if (type === 'line-program-defined') {
                     lineStyle = getLineStyle(el.id);
                  }
                  let polygonColor = null;
                  if (type === 'polygon-program-defined') {
                     polygonColor = getSpeciesColor(el.id);
                  }
                  // Check for element text types
                  if (el.type === "text") {
                     return (
                        <Box key={el.id} sx={{ bgcolor: 'seconday.darker', padding: '4px 10px', backgroundColor: theme.palette.secondary.light }} >
                           <Typography variant="subtitle2" color="textPrimary" sx={{ fontSize: 16 }}>
                              {el.label}
                           </Typography>
                        </Box>
                     )
                  }
                  // LINE & ICON & COLOR
                  if (type === 'line-program-defined' || type === 'line-non-program-defined' || type === 'icon' || type === 'color') {
                     return <MenuItem key={el.id} value={el.id} disabled={el.status === 'disabled'} className="MenuItem" sx={{ borderBottom: el.id === "Text" ? 1 : 0, borderColor: 'divider' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                           {type === 'line-program-defined' && <div style={{ height: height, marginRight: 6 }}>{getLineComponentById((lineStyle && lineStyle.dashArray) ? "Dashed" : "Solid", height, lineStyle ? lineStyle.color : "Black")}</div>}
                           {type === 'line-non-program-defined' && <div style={{ height: height, marginRight: 6 }}>{getLineComponentById(el.id, height, lineColor === undefined ? 'Black' : getColorComponentById(lineColor as ColorStyle))}</div>}
                           {type === 'icon' && <div style={{ height: height, marginRight: 6 }}>{getIconComponentById(el.id, height)}</div>}
                           {type === 'color' && <div style={{ height: height, marginRight: 6 }}><Box sx={{ bgcolor: getColorComponentById(el.id), height: height, width: width }} /></div>}
                           <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {type === 'line-program-defined' ? el.label : translate(el.label)}
                           </div>
                        </div>
                     </MenuItem>
                  } else if (type === 'polygon-program-defined' || type === 'polygon-non-program-defined') {
                     return <MenuItem key={el.id} value={el.id} disabled={el.status === 'disabled'} className="MenuItem" sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                           {type === 'polygon-program-defined' && <div style={{ height: height, marginRight: 6 }}>{getPolygonComponentById("Filled", height, polygonColor as ColorStyle)}</div>}
                           {type === 'polygon-non-program-defined' && <div style={{ height: height, marginRight: 6 }}>{getPolygonComponentById(el.id, height, lineColor === undefined ? 'Black' : getColorComponentById(lineColor as ColorStyle))}</div>}
                           <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {type === "polygon-program-defined" ? el.label : translate(el.label)}
                           </div>
                        </div>
                     </MenuItem>
                  }

                  if (!legendA) return <MenuItem key={el.id} value={el.id} disabled={el.status === 'disabled'} className="MenuItem">
                     <Tooltip placement={tooltipPlacement} title={el.tooltip ? el.tooltip : null} key={el.id + "tooltip"}>
                        <span>{el.label}</span>
                     </Tooltip>
                  </MenuItem>

                  return <MenuItem key={el.id} value={el.id} disabled={el.status === 'disabled'} className="MenuItem">
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{
                           height: height,
                           width: 8,
                           marginRight: 6,
                           marginBottom: 2,
                           borderRadius: 4,
                           backgroundColor: el.color
                        }} />
                        {el.label}
                     </div>
                  </MenuItem>
               })}
            </Select>
         </FormControl>
      </>
   )
}

export default Selector;
