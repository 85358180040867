// action - state management
import {
   MAP_DATA_RESET_STATE,
   MAP_DATA_SET_TRIGGER_RERENDER,
   SET_SELECTED_FEATURE_ID,
   SET_SELECTED_FEATURE,
   SET_SELECTED_ACTIVITY_ID,
   SET_ADD_STAND_DATA,
   SET_USE_PROGRAM_DEFINED_SETTING,
   SET_AREA_BEARING_SETTING,
   SET_FEATURE_EDIT_MODE,
   SET_DRAW_MODE,
   SET_TABLE_STATE,
   SET_BOARD_STATE,
   SET_ESTATE_MAP_FILTER_FEATURE_IDS,
   SET_CATEGORIES,
   SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE,
   SET_ACTIVE_EXTERNAL_LAYERS_LEGEND,
   SET_NOTES_LAYER_STATE,
   MAP_DATA_API_CALL_START,
   MAP_DATA_API_CALL_FAIL,
   ADDED_ACTIVITY_SUCCESS,
   MODIFIED_ACTIVITY_SUCCESS,
   REMOVED_ACTIVITY_SUCCESS,
   ADDED_FEATURE_SUCCESS,
   MODIFIED_FEATURE_SUCCESS,
   REMOVED_FEATURE_SUCCESS,
   ADDED_ACTIVITY_FEATURE_SUCCESS,
   MODIFIED_ACTIVITY_FEATURE_SUCCESS,
   REMOVED_ACTIVITY_FEATURE_SUCCESS,
   ADDED_THINNING_DATA_SUCCESS,
   MODIFIED_THINNING_DATA_SUCCESS,
   REMOVED_THINNING_DATA_SUCCESS,
   FETCH_STAND_DATA_SUCCESS,
   SAVE_FEATURE_SUCCESS,
   SAVE_STAND_DATA_SUCCESS,
   SAVE_THINNING_DATA_SUCCESS,
   ADDED_NOTE_SUCCESS,
   MODIFIED_NOTE_SUCCESS,
   REMOVED_NOTE_SUCCESS,
   DELETE_FEATURE_SUCCESS,
   SAVE_ACTIVITY_SUCCESS,
   SET_REMOVE_FEATURE_ID,
} from "./actions";

// Utility
import { updateObject } from "utils/updateObject";

// Types
import { MapDataProps, MapDataActionProps } from "types/mapData";

// Initial state
const initialState: MapDataProps = {
   loading: false,
   error: null,
   triggerRerender: 0,
   selectedFeature: null,
   selectedActivityId: null,
   addStandData: true,
   useProgramDefinedSetting: true,
   areaBearingSetting: true,
   editMode: false,
   drawMode: false,
   lastSavedFeature: { id: null, geometry: null },
   changeFeature: null,
   removeFeatureId: null,
   changeNote: null,
   removeNoteId: null,
   features: {},
   standData: {},
   thinningData: {}, 
   activities: {},
   activityFeatures: {},
   notes: {},
   notesLayerActive: true,
   tableState: {
      inventory: {
         selectedMapIds: [],
         showFilterResultInMap: false,
         showColumnFilters: false,
         tableType: "full",
         columnFilters: [],
         columnVisibility: {},
         sorting: [],
         globalFilter: "",
         charts: {
            tabValue: 0,
            estimationParameters: {
              thinningType: undefined,
              thinningStartAge: 25,
              thinningInterval: 5,
              thinningPercentage: 20,
              sliderValue: 10,
            },
          },
      }
   },
   boardState: {
      projectBoard: {
         selectedProjectIds: [],
         showFilterResultInMap: false,
         globalFilter: "",
      },
   },
   estateMapFilterFeatureIds: {},
   categories: null,
   categoriesFilterLogicType: "AND",
   activeExternalLayersLegend: [],
}
const mapData = (state = initialState, action: MapDataActionProps) => {
   // --- Set app specific data --- //
   const resetState = () => {
      return initialState;
   }
   const setTriggerRerender = (state: MapDataProps) => {
      return updateObject(state, { triggerRerender: state.triggerRerender + 1 });
   }
   const setSelectedFeatureId = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { selectedFeatureId: action.selectedFeatureId, triggerRerender: state.triggerRerender + 1 });
   }
   const setSelectedFeature = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { selectedFeature: action.selectedFeature, lastSavedFeature: action.lastSavedFeature });
   }
   const setSelectedActivityId = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { selectedActivityId: action.selectedActivityId, triggerRerender: state.triggerRerender + 1 });
   }
   const setRemoveFeatureId = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { removeFeatureId: action.removeFeatureId });
   }
   const setAddStandData = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { addStandData: action.addStandData });
   }
   const setUseProgramDefinedSetting = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { useProgramDefinedSetting: action.useProgramDefinedSetting });
   }
   const setAreaBearingSetting = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { areaBearingSetting: action.areaBearingSetting });
   }
   const setFeatureEditMode = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { editMode: action.editMode });
   }
   const setDrawMode = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { drawMode: action.drawMode });
   }
   const setTableState = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { tableState: action.tableState });
   }
   const setBoardState = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { boardState: action.boardState });
   }
   const setEstateMapFilterFeatureIds = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { estateMapFilterFeatureIds: action.estateMapFilterFeatureIds });
   }
   const setCategories = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { categories: action.categories });
   }
   const setMapCategoriesFilterLogicType = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { categoriesFilterLogicType: action.categoriesFilterLogicType });
   }
   const setActiveExternalLayersLegend = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { activeExternalLayersLegend: action.activeExternalLayersLegend })
   }
   const setNotesLayerState = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { notesLayerActive: action.notesLayerActive });
   }
   // --- Fetch data --- //
   const mapDataAPICallStart = (state: MapDataProps) => {
      return updateObject(state, { loading: true });
   }

   const mapDataAPICallFail = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { loading: false, error: action.error });
   }

   const addedActivitySuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const projectId = data.projectId;
      const docId = data.id;
      let updatedActivities = { ...state.activities };
      // Add data
      updatedActivities[projectId] = { ...updatedActivities[projectId], [docId]: data };
      return updateObject(state, {
         loading: false,
         activities: updatedActivities,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const modifiedActivitySuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const projectId = data.projectId;
      const docId = data.id;
      let updatedActivities = { ...state.activities };
      // Update data
      updatedActivities[projectId][docId] = data;
      return updateObject(state, {
         loading: false,
         activities: updatedActivities,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const removedActivitySuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const projectId = data.projectId;
      const docId = data.id;
      let updatedActivities = { ...state.activities };
      // Remove data
      delete updatedActivities[projectId][docId];
      // setRemovedFeatureId(state, docId);
      return updateObject(state, {
         loading: false,
         activities: updatedActivities,
         selectedActivityId: state.selectedActivityId === docId ? null : state.selectedActivityId,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const addedFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      let mapId = data.properties.mapId;
      const docId = data.properties.id;
      // Update the feature state
      let updatedFeatures = { ...state.features };
      // Add data
      updatedFeatures[mapId] = { ...updatedFeatures[mapId], [docId]: data };
      return updateObject(state, {
         loading: false,
         changeFeature: docId,
         features: updatedFeatures,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const modifiedFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      let mapId = data.properties.mapId;
      const docId = data.properties.id;
      let updatedFeatures = { ...state.features };
      // Update data
      if (updatedFeatures && updatedFeatures[mapId] && updatedFeatures[mapId][docId]) {
         updatedFeatures[mapId][docId] = data;
      } else {
         // Feature not found add it (can be caused by private or internal category change)
         updatedFeatures[mapId] = { ...updatedFeatures[mapId], [docId]: data };
      }
      return updateObject(state, {
         loading: false,
         changeFeature: docId,
         features: updatedFeatures,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const removedFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      let mapId = data.properties.mapId;
      const docId = data.properties.id;
      let updatedFeatures = { ...state.features };
      // Remove data
      if (updatedFeatures && updatedFeatures[mapId] && updatedFeatures[mapId][docId]) {
         delete updatedFeatures[mapId][docId];
      }
      return updateObject(state, {
         loading: false,
         removeFeatureId: docId,
         selectedFeature: null,
         features: updatedFeatures,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const addedActivityFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      const activityId = data.properties.activityId;
      const docId = data.properties.id;
      let updatedActivityFeatures = { ...state.activityFeatures };
      // Add data
      updatedActivityFeatures[activityId] = { ...updatedActivityFeatures[activityId], [docId]: data };
      return updateObject(state, {
         loading: false,
         activityFeatures: updatedActivityFeatures,
         changeFeature: docId,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const modifiedActivityFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      const activityId = data.properties.activityId;
      const docId = data.properties.id;
      let updatedActivityFeatures = { ...state.activityFeatures };
      // Update data
      updatedActivityFeatures[activityId][docId] = data;
      return updateObject(state, {
         loading: false,
         activityFeatures: updatedActivityFeatures,
         changeFeature: docId,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const removedActivityFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload.dataConv;
      const activityId = data.properties.activityId;
      const docId = data.properties.id;
      let updatedActivityFeatures = { ...state.activityFeatures };
      // Remove data
      delete updatedActivityFeatures[activityId][docId];
      return updateObject(state, {
         loading: false,
         activityFeatures: updatedActivityFeatures,
         removeFeatureId: docId,
         selectedFeature: null,
         triggerRerender: state.triggerRerender + 1
      });
   }

   const fetchStandDataSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      let updatedState = { ...state.standData };
      updatedState = { ...state.standData, [action.mapId!]: action.payload! };
      return updateObject(state, {
         loading: false,
         standData: updatedState
      })
   }

   const addedThinningDataSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const { id, mapId } = data;
      let updatedThinningData = { ...state.thinningData };
      // Add data
      updatedThinningData[mapId] = { ...updatedThinningData[mapId], [id]: data };
      return updateObject(state, { 
         thinningData: updatedThinningData,
         loading: false 
      });
   }

   const modifiedThinningDataSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const { id, mapId } = data;
      let updatedThinningData = { ...state.thinningData };
      // Update data
      updatedThinningData[mapId][id] = data;
      return updateObject(state, { 
         thinningData: updatedThinningData,
         loading: false 
      });
   }

   const removedThinningDataSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const { id, mapId } = data;
      let updatedThinningData = { ...state.thinningData };
      // Remove data
      delete updatedThinningData[mapId][id];
      return updateObject(state, { 
         thinningData: updatedThinningData,
         loading: false 
      });
   }

   const addedNoteSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const mapId = data.mapId;
      const docId = data.id;
      let updatedNotes = { ...state.notes };
      // Add data
      updatedNotes[mapId] = { ...updatedNotes[mapId], [docId]: data };
      return updateObject(state, {
         loading: false,
         changeNote: docId,
         notes: updatedNotes,
      });
   }

   const modifiedNoteSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const mapId = data.mapId;
      const docId = data.id;
      let updatedNotes = { ...state.notes };
      // Update data
      updatedNotes[mapId][docId] = data;
      return updateObject(state, {
         loading: false,
         changeNote: docId,
         notes: updatedNotes,
      });
   }

   const removedNoteSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      const data = action.payload;
      const mapId = data.mapId;
      const docId = data.id;
      let updatedNotes = { ...state.notes };
      // Remove data
      delete updatedNotes[mapId][docId];
      // setRemovedFeatureId(state, docId);
      return updateObject(state, {
         loading: false,
         removeNoteId: docId,
         // selectedNote: null,
         notes: updatedNotes,
      });
   }

   // --- Activities --- //
   const saveActivitySuccess = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { loading: false });

   }
   // --- Features --- //
   // Save feature
   const saveFeatureSuccess = (state: MapDataProps, action: MapDataActionProps) => {
      return updateObject(state, { loading: false, lastSavedFeature: action.lastSavedFeature })
   }
   // Delete feature
   const deleteFeatureSuccess = (state: MapDataProps) => {
      return updateObject(state, { loading: false });
   }
   // --- Stand data --- //
   const saveStandDataSuccess = (state: MapDataProps) => {
      return updateObject(state, { loading: false });
   }
   // --- Thinning data --- //
   const saveThinningDataSuccess = (state: MapDataProps) => {
      return updateObject(state, { loading: false });
   }

   switch (action.type) {
      case MAP_DATA_RESET_STATE: return resetState();
      case MAP_DATA_SET_TRIGGER_RERENDER: return setTriggerRerender(state);
      case SET_SELECTED_FEATURE_ID: return setSelectedFeatureId(state, action);
      case SET_SELECTED_FEATURE: return setSelectedFeature(state, action);
      case SET_SELECTED_ACTIVITY_ID: return setSelectedActivityId(state, action);
      case SET_REMOVE_FEATURE_ID: return setRemoveFeatureId(state, action);
      case SET_ADD_STAND_DATA: return setAddStandData(state, action);
      case SET_USE_PROGRAM_DEFINED_SETTING: return setUseProgramDefinedSetting(state, action);
      case SET_AREA_BEARING_SETTING: return setAreaBearingSetting(state, action);
      case SET_FEATURE_EDIT_MODE: return setFeatureEditMode(state, action);
      case SET_DRAW_MODE: return setDrawMode(state, action);
      case SET_TABLE_STATE: return setTableState(state, action);
      case SET_BOARD_STATE: return setBoardState(state, action);
      case SET_ESTATE_MAP_FILTER_FEATURE_IDS: return setEstateMapFilterFeatureIds(state, action);
      case SET_CATEGORIES: return setCategories(state, action);
      case SET_MAP_CATEGORIES_FILTER_LOGIC_TYPE: return setMapCategoriesFilterLogicType(state, action);
      case SET_ACTIVE_EXTERNAL_LAYERS_LEGEND: return setActiveExternalLayersLegend(state, action);
      case SET_NOTES_LAYER_STATE: return setNotesLayerState(state, action);

      case MAP_DATA_API_CALL_START: return mapDataAPICallStart(state);
      case MAP_DATA_API_CALL_FAIL: return mapDataAPICallFail(state, action);
      case ADDED_ACTIVITY_SUCCESS: return addedActivitySuccess(state, action);
      case MODIFIED_ACTIVITY_SUCCESS: return modifiedActivitySuccess(state, action);
      case REMOVED_ACTIVITY_SUCCESS: return removedActivitySuccess(state, action);
      case ADDED_FEATURE_SUCCESS: return addedFeatureSuccess(state, action);
      case MODIFIED_FEATURE_SUCCESS: return modifiedFeatureSuccess(state, action);
      case REMOVED_FEATURE_SUCCESS: return removedFeatureSuccess(state, action);
      case ADDED_ACTIVITY_FEATURE_SUCCESS: return addedActivityFeatureSuccess(state, action);
      case MODIFIED_ACTIVITY_FEATURE_SUCCESS: return modifiedActivityFeatureSuccess(state, action);
      case REMOVED_ACTIVITY_FEATURE_SUCCESS: return removedActivityFeatureSuccess(state, action);

      case FETCH_STAND_DATA_SUCCESS: return fetchStandDataSuccess(state, action);

      case ADDED_THINNING_DATA_SUCCESS: return addedThinningDataSuccess(state, action);
      case MODIFIED_THINNING_DATA_SUCCESS: return modifiedThinningDataSuccess(state, action);
      case REMOVED_THINNING_DATA_SUCCESS: return removedThinningDataSuccess(state, action);

      case SAVE_ACTIVITY_SUCCESS: return saveActivitySuccess(state, action);

      case SAVE_FEATURE_SUCCESS: return saveFeatureSuccess(state, action);
      case DELETE_FEATURE_SUCCESS: return deleteFeatureSuccess(state);
      case SAVE_STAND_DATA_SUCCESS: return saveStandDataSuccess(state);
      case SAVE_THINNING_DATA_SUCCESS: return saveThinningDataSuccess(state);

      case ADDED_NOTE_SUCCESS: return addedNoteSuccess(state, action);
      case MODIFIED_NOTE_SUCCESS: return modifiedNoteSuccess(state, action);
      case REMOVED_NOTE_SUCCESS: return removedNoteSuccess(state, action);
      default: return state;
   }
}

export default mapData;